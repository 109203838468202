import React from "react"
import "./footer.scss"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

const Footer = () => {
  const intl = useIntl()
  return (
    <footer className={"footer"}>
      <div className="footer-top">
        <div className="container footer-top-container">
          <div className="footer-top-column">
            <div className="logo">
              <Link
                to={"/"}>
                <img loading="lazy" src="https://luxdecor.ua/img/LuxDecor-1.png"/>
              </Link>
            </div>
          </div>
          <div className='footer-three-block'>
          <div className="footer-top-column">
            <div className="list">
              <h3 className="list-title">{intl.formatMessage({ id: `footer.manufacture` })}</h3>
              <ul>
                <li>{intl.formatMessage({ id: `footer.item1` })}</li>
                <li>{intl.formatMessage({ id: `footer.item2` })}</li>
                <li>{intl.formatMessage({ id: `footer.item3` })}</li>
                {/*<li>{intl.formatMessage({ id: `footer.item4` })}</li>*/}
                <li>{intl.formatMessage({ id: `footer.item5` })}</li>
                <li>{intl.formatMessage({ id: `footer.item6` })}</li>
              </ul>
            </div>
          </div>
          <div className="footer-top-column">
            <div className="list">
              <h3 className="list-title">{intl.formatMessage({ id: `footer.column2` })}</h3>
              <ul>
                <li>{intl.formatMessage({ id: `footer.column2item1` })}</li>
                <li>{intl.formatMessage({ id: `footer.column2item2` })}</li>
                <li><a href="tel:(096) 099-00-99">{intl.formatMessage({ id: `footer.column2item3_1` })}</a></li>
                  <li><a href="tel:(032) 295-63-00">{intl.formatMessage({ id: `footer.column2item3` })}</a></li>
                <li>{intl.formatMessage({ id: `footer.column2item4` })}</li>
              </ul>
            </div>
          </div>
          <div className="footer-top-column">
            <div className="list">
              <h3 className="list-title">{intl.formatMessage({ id: `footer.siteMap` })}</h3>
              <ul>
                <li><Link to={"/"}>{intl.formatMessage({ id: `header.home` })} </Link></li>
                <li><Link to={"products"}>{intl.formatMessage({ id: `header.products` })}</Link></li>
                <li><Link to={"where-to-buy"}>{intl.formatMessage({ id: `header.whereToBuy` })}</Link></li>
                <li><Link to={"contacts"}>{intl.formatMessage({ id: `header.contacts` })}</Link></li>
              </ul>
            </div>
            <a href="https://luxdecor.ua/img/catalog.pdf" target="_blank" className="footer-download-btn">{intl.formatMessage({ id: `footer.download` })}</a>
          </div>
          </div>
          {/*<div className="footer-top-column">*/}
          {/*<div className="dropdown-list list">*/}
          {/*<h3 className="list-title">{intl.formatMessage({ id: `footer.productCategory` })}</h3>*/}
          {/*<ul>*/}
          {/*<li>*/}
          {/*<Link*/}
          {/*to={"product-category/wood-stains-and-varnishes"}>{intl.formatMessage({ id: `footer.category1` })}</Link>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*<Link to={"product-category/paints"}>{intl.formatMessage({ id: `footer.category2` })}</Link></li>*/}
          {/*<li><Link*/}
          {/*to={"product-category/impregnating-agents"}>{intl.formatMessage({ id: `footer.category3` })}</Link>*/}
          {/*</li>*/}

          {/*</ul>*/}
          {/*/!*<button className="dropdown-list-btn">DOWNLOAD CATALOGUE</button>*!/*/}
          {/*</div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className="footer-bottom">

        <p className="container footer-bottom-copyright">
          © Copyright 2020 <a
          href={"https://unicell.pl/en/"}> {intl.formatMessage({ id: `footer.copywrite1` })} </a> | {intl.formatMessage({ id: `footer.copywrite2` })}
        </p>
      </div>
      {/* <h1 className="cscsc">Hello</h1> */}
    </footer>
  )
}

export default Footer
