import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  uk: "https://cdn.ip2location.com/assets/img/flags/ru.png",
  ru: "https://cdn.ip2location.com/assets/img/flags/ua.png"
};

const Language = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map((language, i) => (
            <img key={i} src={languageName[language]} style={{display: currentLocale !== language ? 'block' : 'none'}} onClick={() => changeLocale(language)} alt=""/>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
