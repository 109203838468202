import React, {useEffect} from "react"
import {Link} from "gatsby"
import "./heade.scss"
import Language from "../language/language"
import {useIntl} from "gatsby-plugin-intl"

export const Header = (props) => {
    const intl = useIntl();

    useEffect(() => {
        document.querySelector(".burger-button").onclick = () => {
            document.querySelector(".header").classList.toggle("active-menu")
            document.querySelector(".burger-button").classList.toggle("active-burger")
        }
    });

    return (
        <header className="header">
            <div className="container">
                <Link
                    to={"/"}>
                    <img loading="lazy"
                         src="https://luxdecor.ua/img/LuxDecor-1.png"
                         alt=""/>
                </Link>
                <ul className={"menu-list"}>
                    <li key={"1"}>
                        <Link
                            to={"/"}
                            activeClassName="active">
                            {intl.formatMessage({id: `header.home`})}
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={"/chalk-it-page"}
                            activeClassName="active">
                            {intl.formatMessage({id: `header.chalk-it`})}
                        </Link>
                    </li>
                    <li key={"2"} className={"products-item products-dropdown"}>
                        <Link
                            to={"products/"}
                            activeClassName="active">
                            {intl.formatMessage({id: `header.products`})}
                        </Link>
                        <div className={"dropdown-content"}>
                            <ul>
                                <li key={"5"} className={"dropdown-content-item"}>
                                    <Link to={"product-category/wood-stains-and-varnishes"}>
                                        {intl.formatMessage({id: `categories.laki`})}
                                    </Link>
                                </li>
                                <li key={"6"} className={"dropdown-content-item"}>
                                    <Link to={"product-category/paints"}>
                                        {intl.formatMessage({id: `categories.paints`})}
                                    </Link>
                                </li>
                                <li key={"7"} className={"dropdown-content-item"}>
                                    <Link to={"product-category/impregnating-agents"}>
                                        {intl.formatMessage({id: `categories.impegnant`})}
                                    </Link>
                                </li>
                                <li key={"8"} className={"dropdown-content-item"}>
                                <Link to={"product-category/chalk-it"}>
                                {/*{intl.formatMessage({ id: `categories.oils` })}*/}
                                Chalk-it
                                </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li key={"101"} className={"mobile-item"}>
                        <Link to={"product-category/wood-stains-and-varnishes"}>
                            {intl.formatMessage({id: `categories.laki`})}
                        </Link>
                    </li>
                    <li key={"102"} className={"mobile-item"}>
                        <Link to={"product-category/paints"}>
                            {intl.formatMessage({id: `categories.paints`})}
                        </Link>
                    </li>
                    <li key={"103"} className={"mobile-item"}>
                        <Link to={"product-category/impregnating-agents"}>
                            {intl.formatMessage({id: `categories.impegnant`})}
                        </Link>
                    </li>
                    <li key={"3"}>
                        <Link
                            to={"/where-to-buy"}
                            activeClassName="active">
                            {intl.formatMessage({id: `header.whereToBuy`})}
                        </Link>
                    </li>
                    <li key={"4"}>
                        <Link
                            to={"/contacts"}
                            activeClassName="active">
                            {intl.formatMessage({id: `header.contacts`})}
                        </Link>
                    </li>
                    <li key={"5"}>
                        <Language/>
                    </li>
                </ul>
                <div className="burger-button">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    )
}

export default Header
